.App {

}

.fade-out {
    animation: fadeOut ease 4s;
    -webkit-animation: fadeOut ease 4s;
    -moz-animation: fadeOut ease 4s;
    -o-animation: fadeOut ease 4s;
    -ms-animation: fadeOut ease 4s;
}

.fade-in-out {
    -webkit-animation: fadeInOut 3s;
    opacity: 0;
}

.height-fifty-pixels {
    height: 44px;
}

.height-fifteen-pixels {
    height: 15px;
}


.height-three-pixels {
    height: 3px;
}

.height-five-pixels {
    height: 5px;
}

.height-one-pixel {
    height: 1px;
}

.bg-color-bordeaux {
    background-color: #630d35;
}

.bg-color-white {
    background-color: #FFFFFF;
}

.text-color-bordeaux {
    color: #630d35;
}

.text-color-white {
    color: #FFFFFF;
}

.form-bottle {
    width: 100%;
}

.td-bottle {
    width: 15%;
}

.input-bottle {
    width: 100%;
    height: 100%;
}

.input-search {
    width: 100%;
    float: right;
    clear: both;
}

.div-right {
    text-align: right;
}

.div-font {
    font-size: 12px;
}

::placeholder {
    font-size: 12px;
}

.has-search .form-control {
    padding-left: 2.375rem;

}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.popup {
    width: 90%;
    padding: 15px;
    left: 0;
    margin-left: 5%;
    border: 1px solid #ccc;
    border-radius: 10px;
    background: white;
    position: absolute;
    top: 15%;
    box-shadow: 5px 5px 5px #000;
    z-index: 10001;
}

.overlay-fade-in-out {
    display: none;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .85);
    z-index: 10000;
    -webkit-animation: fadeInOut 1s;
    opacity: 0;
}

.overlay {
    display: none;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .85);
    z-index: 10000;
}


.navbar-collapse.in {
    display: block !important;
}

.button-link {
    font: bold 12px Arial;
    text-decoration: none;
    background-color: #630d35;
    color: #FFFFFF;
    padding: 8px 12px 8px 12px;
    border-radius: 8px;
}

.anchor {
    scroll-margin-top: 100px;
}

.navbar-custom {
    background-color: lightgreen;
}

.container {
    overflow: auto; /* Clearfix */
}

.element1, .element2 {
    float: left;
    width: 50%; /* Each element takes up 50% of the container */
}


@-webkit-keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    35% {
        opacity: 1;
    }
    72% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-ms-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}




